<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-7 pt-lg-7">
      <div class="header-body text-center mb-5">
        <b-row class="justify-content-center">
          <b-col xl="5" lg="6" md="8" class="px-5">
            <h1 class="text-white">Welcome!</h1>
            <p class="text-lead text-white">
              Log in to continue your otinga journey
            </p>
          </b-col>
        </b-row>
      </div>
      <!-- <div class="separator separator-bottom separator-skew zindex-100">
                <svg
                    x="0"
                    y="0"
                    viewBox="0 0 2560 100"
                    preserveAspectRatio="none"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <polygon
                        class="fill-default"
                        points="2560 0 2560 100 0 100"
                    ></polygon>
                </svg>
            </div> -->
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-2">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <div class="row justify-content-center">
              <div class="col-6 m-3 d-flex justify-content-center">
                <img
                  src="img/brand/logos/otinga.svg"
                  class="img-fluid"
                  style="max-height: 13rem; width: auto"
                />
              </div>
            </div>

            <!-- <b-card-header class="bg-transparent pb-5">
                            <div class="text-muted text-center mt-2 mb-3">
                                 <small>Sign in with</small>
                            </div>
                            <div class="btn-wrapper text-center">
                                <base-button
                                    type="white"
                                    :pill="true"
                                    class=""
                                    size="sm"
                                    @click="
                                        authenticate('google-oauth2', {
                                            path: next,
                                        })
                                    "
                                    ><span class="btn-inner--icon p-1"
                                        ><img src="img/icons/common/google.svg"
                                    /></span>
                                    <span class="btn-inner--text text-black"
                                        >&nbsp; Google</span
                                    ></base-button
                                > -->
            <!-- <a href="#" class="btn btn-neutral btn-icon">
                                    <span class="btn-inner--icon"
                                        ><img src="img/icons/common/apple.svg"
                                    /></span>
                                    <span class="btn-inner--text">Apple</span>
                                </a> -->
            <!-- </div>
                        </b-card-header> -->
            <b-card-body class="px-lg-5 py-lg-4">
              <div class="text-center text-muted mb-3">
                <!-- <small>or sign in with credentials</small> -->
                <small>Sign in with </small>
              </div>
              <!-- <div class="text-center text-muted mb-4">
                                <small>Sign in with credentials</small>
                            </div> -->
              <validation-observer
                v-slot="{ handleSubmit }"
                ref="formValidator"
              >
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input
                    alternative
                    class="mb-3"
                    name="Email"
                    :rules="{ required: true, email: true }"
                    prepend-icon="ni ni-email-83"
                    placeholder="Email"
                    v-model="model.email"
                  >
                  </base-input>

                  <base-input
                    alternative
                    class="mb-3"
                    name="Password"
                    :rules="{ required: true, min: 6 }"
                    prepend-icon="ni ni-lock-circle-open"
                    type="password"
                    placeholder="Password"
                    v-model="model.password"
                  >
                  </base-input>

                  <div class="text-center">
                    <div
                      class="invalid-feedback"
                      style="display: block"
                      v-if="error_message"
                    >
                      {{ error_message }}
                    </div>
                    <base-button
                      type="primary"
                      :pill="true"
                      native-type="submit"
                      class="my-4"
                      :loading="isLoading"
                      >Log in</base-button
                    >
                  </div>
                </b-form>
              </validation-observer>
              <b-row class="mt-4">
                <b-col cols="6">
                  <router-link to="/forgot_password"
                    ><small>Forgot password?</small></router-link
                  >
                </b-col>
                <b-col cols="6" class="text-right">
                  <router-link
                    :to="{
                      path: '/register',
                      query: $route.query,
                    }"
                    ><small>Create new account</small></router-link
                  >
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import rest_api from "@/rest_api";
import { authMixin } from "@/mixins/authMixin";

export default {
  name: "Login",
  emits: ["updatedLoggedInStatus"],
  mixins: [authMixin],
  data() {
    return {
      model: {
        email: "",
        password: "",
      },
      isLoading: false,
      error_message: "",
      next: null,
    };
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      this.error_message = "";
      setTimeout(() => {
        user_util
          .login(this.model.email, this.model.password)
          .then((response) => {
            console.log(response);
            if (response) {
              this.isLoading = false;

              if (this.next != null) {
                let { next, ...remaining_query } = this.$route.query;
                this.$router.push({
                  path: this.next,
                  query: remaining_query,
                });
              } else {
                this.$router.push({ path: "/", query: this.$route.query });
              }
            } else {
              this.error_message =
                "Unable to login with provided credentials, please try again.";
              this.isLoading = false;
            }
          })
          .then((user_data) => {
            this.$emit("updatedLoggedInStatus");
          })
          .catch((err) => {
            console.log(err);
            this.error_message =
              "Unable to login with provided credentials, please try again.";
            this.isLoading = false;
          });
      }, 2000);
    },
    get_next() {
      if (this.$route.query.next) {
        this.next = this.$route.query.next;
      }
    },
  },
  mounted() {
    this.get_next();
  },
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        this.get_next();
      },
    },
  },
};
</script>

<style scoped></style>
