<template>
    <card>
        <b-row align-v="center" slot="header">
            <b-col cols="8">
                <h3 class="mb-0">Edit profile</h3>
            </b-col>
            <!-- <b-col cols="4" class="text-right">
      <a href="#!" class="btn btn-sm btn-primary">Settings</a>
    </b-col> -->
        </b-row>
        <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <b-form @submit.prevent="handleSubmit(onSubmit)">
                <div class="pl-lg-4">
                    <b-row>
                        <b-col lg="6">
                            <base-input
                                label="First Name"
                                class="mb-3"
                                prepend-icon="ni ni-circle-08"
                                :placeholder="
                                    rest_user.first_name
                                        ? rest_user.first_name
                                        : 'First Name'
                                "
                                name="Name"
                                :rules="{ required: true }"
                                v-model="rest_user.first_name"
                                :value="
                                    rest_user.first_name
                                        ? rest_user.first_name
                                        : ''
                                "
                            >
                            </base-input>
                        </b-col>
                        <b-col lg="6">
                            <base-input
                                label="Last Name"
                                class="mb-3"
                                prepend-icon="ni ni-circle-08"
                                :placeholder="
                                    rest_user.last_name
                                        ? rest_user.last_name
                                        : 'Last Name'
                                "
                                name="Name"
                                :rules="{ required: true }"
                                v-model="rest_user.last_name"
                                :value="
                                    rest_user.last_name
                                        ? rest_user.last_name
                                        : ''
                                "
                            >
                            </base-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6">
                            <base-input
                                label="Email"
                                class="mb-3"
                                name="Email"
                                :rules="{ required: true, email: true }"
                                prepend-icon="ni ni-email-83"
                                :placeholder="
                                    rest_user.email ? rest_user.email : 'Email'
                                "
                                v-model="rest_user.email"
                                :value="rest_user.email ? rest_user.email : ''"
                            ></base-input
                        ></b-col>
                    </b-row>
                </div>
                <!-- <hr class="my-4" /> -->

                <!-- Contact information -->
                <!-- <h6 class="heading-small text-muted mb-4">
                  Contact information
              </h6>

              <div class="pl-lg-4">
                  <b-row>
                      <b-col lg="6">
                          <base-input
                              label="Cell number"
                              class="mb-3"
                              name="CellNumber"
                              :rules="{ required: true, digits: 10 }"
                              prepend-icon="fa fa-phone"
                              :placeholder="
                                  gql_user.phone_number
                                      ? gql_user.phone_number
                                      : 'Cell Number'
                              "
                              v-model="gql_user.phone_number"
                              :value="
                                  gql_user.phone_number
                                      ? gql_user.phone_number
                                      : ''
                              "
                          ></base-input>
                      </b-col>
                      <b-col lg="6">
                          <base-input
                              label="Backup number"
                              class="mb-3"
                              name="secondaryNumber"
                              :rules="{ required: false, digits: 10 }"
                              prepend-icon="fa fa-phone"
                              :placeholder="
                                  gql_user.secondary_phone_number
                                      ? gql_user.secondary_phone_number
                                      : 'Backup Number'
                              "
                              v-model="gql_user.cell_number"
                              :value="
                                  gql_user.secondary_phone_number
                                      ? gql_user.secondary_phone_number
                                      : ''
                              "
                          ></base-input>
                      </b-col>
                  </b-row>
              </div> -->
                <!-- <hr class="mb-0" /> -->
                <b-row class="text-right mt-0"
                    ><b-col>
                        <div
                            class="invalid-feedback"
                            style="display: block"
                            v-if="error_message"
                        >
                            {{ error_message }}
                        </div>
                        <base-button
                            type="default"
                            :pill="true"
                            native-type="submit"
                            class="my-4"
                            :loading="is_loading"
                            :success="show_success"
                            >Save</base-button
                        >
                    </b-col></b-row
                >
            </b-form>
        </validation-observer>
    </card>
</template>
<script>
import { GET_USER_PROFILE_CONTACT_DETAIL } from "@/graphql/queries";
import { resolve } from "path";

export default {
    name: "EditProfileForm",
    apollo: {},
    data() {
        return {
            allUserProfileContactDetail: [],
            rest_user: {
                email: "",
                first_name: "",
                last_name: "",
                id: 0,
                id_b64: "",
            },
            gql_user: {
                phone_number: "",
                secondary_phone_number: "",
            },
            is_loading: false,
            error_message: "",
            show_success: false,
        };
    },
    methods: {
        onSubmit() {
            this.is_loading = true;
            let data = {
                first_name: this.rest_user.first_name,
                last_name: this.rest_user.last_name,
                email: this.rest_user.email,
            };
            rest_api.user
                .update(data)
                .catch(() => {
                    this.error_message =
                        "Could not update your profile, please try again later.";
                    this.is_loading = false;
                })

                .then(() => {
                    global_event_emitter.$emit("profile_update");
                    this.is_loading = false;
                    this.show_success = true;
                    setTimeout(() => {
                        this.show_success = false;
                    }, 1000);
                });
        },

        loadUserData() {
            new Promise((res, rej) => {
                this.rest_user = utils.deepcopy(this.$store.getters.getUser);
                this.rest_user.id_b64 = Buffer(
                    "User:" + String(this.rest_user.id)
                ).toString("base64");
                if (this.rest_user.id_b64) {
                    resolve();
                }
            });
        },
    },
    created() {
        this.loadUserData();
    },
};
</script>
<style></style>
