<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <b-container >
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white">Logging Out</h1>
              <p class="text-lead text-white">See you again next time.</p>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <!-- <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div> -->
    </div>
    

  </div>
</template>
<script>
import rest_api from "@/rest_api";
export default {
    name: "Logout",
    emits: ["updatedLoggedInStatus"],
    data() {
        return {};
    },
    mounted() {
        rest_api.user.logout().then((response) => {
            this.$emit("updatedLoggedInStatus");
        });
        setTimeout(() => {
            if (this.$router.currentRoute.fullPath === "/logout") {
                this.$router.push({ path: "login" });
            }
        }, 2000);
    },
};
</script>
<style></style>
