<template>
    <div>
        <!-- Header -->
        <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
            <b-container class="container">
                <div class="header-body text-center">
                    <b-row class="justify-content-center">
                        <b-col xl="5" lg="6" md="8" class="px-5">
                            <img
                                src="img/brand/logos/otingaWhite.svg"
                                class="img-fluid"
                            />
                            <h1 class="text-white">Refund Policy</h1>
                            
                        </b-col>
                    </b-row>
                </div>
            </b-container>
            <!-- <div class="separator separator-bottom separator-skew zindex-100">
                <svg
                    x="0"
                    y="0"
                    viewBox="0 0 2560 100"
                    preserveAspectRatio="none"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <polygon
                        class="fill-default"
                        points="2560 0 2560 100 0 100"
                    ></polygon>
                </svg>
            </div> -->
        </div>
        <!-- Page content -->
        <b-container class="pb-5">
            <!-- Table -->
            <b-row class="justify-content-center">
                <b-col>

                    <h2>1. Introduction</h2>
                    <p>
                        Thank you for choosing Otinga. We understand that
                        sometimes things might not work out as planned, and
                        we're here to help. Please read our refund policy
                        carefully to ensure you are fully informed.
                    </p>

                    <h2>2. 30-Day Money-Back Guarantee</h2>
                    <p>
                        Otinga offers a 30-day money-back guarantee for all our
                        new subscribers. If you're not satisfied with our
                        service for any reason within the first 30 days of your
                        subscription, you can request a full refund.
                    </p>

                    <h2>3. Eligibility for Refunds</h2>
                    <p>To be eligible for a refund:</p>
                    <ul>
                        <li>
                            You must submit your request within 30 days of
                            subscription or purchase.
                        </li>
                        <li>
                            Your account must be in good standing without any
                            breaches of our terms of service or acceptable use
                            policy.
                        </li>
                        <li>
                            You must provide a valid reason for the refund
                            request.
                        </li>
                    </ul>

                    <h2>4. How to Request a Refund</h2>
                    <p>
                        To request a refund, please contact our customer support
                        team at
                        <a href="mailto:info@otinga.io">info@otinga.io</a> with
                        the following details:
                    </p>
                    <ul>
                        <li>Full Name</li>
                        <li>Email Address</li>
                        <li>Subscription Date</li>
                        <li>Reason for Refund Request</li>
                    </ul>
                    <p>
                        Our team will process your request and issue a refund
                        within 5-7 business days.
                    </p>

                    <h2>5. Non-Refundable Situations</h2>
                    <ul>
                        <li>
                            If you violate our terms of service or acceptable
                            use policy.
                        </li>
                        <li>
                            If you've previously received a refund for the same
                            subscription.
                        </li>
                        <li>
                            If the refund request is made after the 30-day
                            money-back guarantee period.
                        </li>
                    </ul>

                    <h2>6. Recurring Subscriptions</h2>
                    <p>
                        For recurring subscription payments, refunds are only
                        applicable for the most recent billing cycle. Refund
                        requests for previous billing cycles will not be
                        processed.
                    </p>

                    <h2>7. Service Upgrades or Downgrades</h2>
                    <p>
                        If you choose to upgrade or downgrade your service plan
                        with Otinga, you will not be eligible for a refund on
                        your previous plan. However, the 30-day money-back
                        guarantee applies to every new subscription or upgrade.
                    </p>

                    <h2>8. Third-Party Integrations</h2>
                    <p>
                        Refunds do not cover any third-party integrations,
                        plugins, or other external services that are billed
                        separately from Otinga.
                    </p>

                    <h2>9. Changes to This Policy</h2>
                    <p>
                        Otinga reserves the right to modify this refund policy
                        at any time. Any changes made will be effective
                        immediately upon posting. It's your responsibility to
                        review the policy periodically.
                    </p>

                    <h2>10. Contact Us</h2>
                    <p>
                        For any questions regarding our refund policy, please
                        contact us at
                        <a href="mailto:info@otinga.io">info@otinga.io</a>.
                    </p>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
export default {
    name: "PrivacyPolicy",
};
</script>
<style></style>
