<template>
    <div>
        <div class="header pb-6 d-flex align-items-center">
            <!-- Mask -->
            <span class="mask bg-white opacity-8"></span>
            <!-- Header container -->
            <b-container fluid class="d-flex align-items-center">
                <b-row>
                    <b-col lg="12"> </b-col>
                </b-row>
            </b-container>
        </div>

        <b-container fluid class="mt--6">
            <!-- <b-row>
                <b-col class="mt-4 mb-2">
                    <div
                        @click="$router.back()"
                        class="icon icon-shape rounded-circle shadow bg-primary text-white"
                    >
                        <i class="fa fa-arrow-left"></i>
                    </div>
                </b-col>
            </b-row> -->
            <b-row>
                <b-col sm="12">
                    <div>
                        <list-my-hackathons> </list-my-hackathons>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="12">
                    <div>
                        <list-involved-hakcathons> </list-involved-hakcathons>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import ListMyHackathons from "@/views/Components/Hackathon/Manage/ListMyHackathons.vue";
import ListInvolvedHakcathons from "@/views/Components/Hackathon/Manage/ListInvolvedHakcathons.vue";

export default {
    name: "ManageHackathons",
    components: {
        ListMyHackathons,
        ListInvolvedHakcathons,
    },

    data() {
        return {};
    },
    methods: {},
};
</script>

<style></style>
