<template>
    <div>
        <!-- Header -->
        <!-- <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9"> -->
        <div class="header bg-gradient-success py-7 ">
            <b-container>
                <div class="header-body text-center mb-7">
                    <b-row class="justify-content-center">
                        <b-col xl="5" lg="6" md="8" class="px-5">
                            <h1 class="text-white">Forgot Password</h1>
                        </b-col>
                    </b-row>
                </div>
            </b-container>
            <!-- <div class="separator separator-bottom separator-skew zindex-100">
                <svg
                    x="0"
                    y="0"
                    viewBox="0 0 2560 100"
                    preserveAspectRatio="none"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <polygon
                        class="fill-default"
                        points="2560 0 2560 100 0 100"
                    ></polygon>
                </svg>
            </div> -->
        </div>
        <!-- Page content -->
        <b-container class="mt--8 pb-5">
            <b-row class="justify-content-center">
                <b-col lg="5" md="7">
                    <b-card no-body class="bg-secondary border-0 mb-0">
                        <div class="row justify-content-center">
                            <div class="col-6 mt-5">
                                <img
                                    src="img/brand/logos/otinga.svg"
                                    class="img-fluid"
                                />
                            </div>
                        </div>

                        <b-card-body class="px-lg-5 py-lg-5">
                            <div class="text-center text-muted mb-4">
                                <small
                                    >To recover your account, please provide
                                    your email.</small
                                >
                            </div>
                            <validation-observer
                                v-slot="{ handleSubmit }"
                                ref="formValidator"
                            >
                                <b-form
                                    role="form"
                                    @submit.prevent="handleSubmit(onSubmit)"
                                >
                                    <base-input
                                        alternative
                                        class="mb-3"
                                        name="Email"
                                        :rules="{ required: true, email: true }"
                                        prepend-icon="ni ni-email-83"
                                        placeholder="Email"
                                        v-model="model.email"
                                        :disabled="
                                            password_reset_request_success ||
                                            is_loading
                                        "
                                    >
                                    </base-input>

                                    <div class="text-center">
                                        <div
                                            class="invalid-feedback"
                                            style="display: block"
                                            v-if="error_message"
                                        >
                                            {{ error_message }}
                                        </div>
                                        <base-button
                                            type="primary"
                                            :pill="true"
                                            native-type="submit"
                                            class="my-4"
                                            :loading="is_loading"
                                            v-if="
                                                !password_reset_request_success
                                            "
                                            >Request password reset
                                            link</base-button
                                        >
                                        <div
                                            v-else
                                            class="text-center text-success m-5"
                                        >
                                            <div>
                                                <h1>
                                                    <i
                                                        class="fas fa-check display-1"
                                                    ></i>
                                                </h1>
                                            </div>
                                            <div class="display-4">
                                                A password reset link is on
                                                it's way.
                                            </div>
                                            <div
                                                class="text-center text-muted m-4"
                                            >
                                                <small
                                                    >Can't find the email? <br>Please check your spam folder for the email as well. </small
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </b-form>
                            </validation-observer>
                            <b-row class="mt-3">
                                <b-col cols="6">
                                    <router-link to="/login"
                                        ><small
                                            >Already have an account?</small
                                        ></router-link
                                    >
                                </b-col>
                                <b-col cols="6" class="text-right">
                                    <router-link to="/register"
                                        ><small
                                            >Create new account</small
                                        ></router-link
                                    >
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import rest_api from "@/rest_api";
export default {
    name: "ForgotPassword",
    data() {
        return {
            model: {
                email: "",
            },
            is_loading: false,
            error_message: "",
            password_reset_request_success: false,
        };
    },
    methods: {
        onSubmit() {
            
            this.error_message = "";

            this.is_loading = true;
            rest_api.user
                .getPasswordResetLink(this.model.email)

                .then((response) => {
                    this.is_loading = false;
                    this.password_reset_request_success = true;
                })
                .catch((err) => {
                    this.is_loading = false;
                    this.error_message =
                        "Unable to reset your account at this time, please try again later.";
                });
        },
    },
};
</script>
