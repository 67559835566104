import axios from "axios";
import store from "@/store";
import rest_api from "@/rest_api";
import router from "@/routes/router";
export const authMixin = {
    methods: {
        authenticate: function (provider, routerPush) {
            if (!provider) {
                provider = "google-oauth2";
            }
            return this.$auth
                .authenticate("google", { provider: provider })
                .then(function (response) {
                    store.store.commit("login", response.data.token);
                    rest_api.user.get();
                    if (response.data.token) {
                        if (routerPush) {
                            router.push(routerPush);
                        } else {
                            router.push({ path: "/dash" });
                        }
                    }

                    // TODO: Raise errors here
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
};
