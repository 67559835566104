<template>
    <div>
        <!-- Hackathons -->
        <b-row class="mt-4">
            <b-col sm="12">
                <b-row class="mb-4">
                    <b-col cols="auto">
                        <h1>My Hackathons</h1>
                    </b-col>

                    <!-- Search -->
                    <b-col
                        sm="3"
                        class="pr-0"
                        v-if="filtered_hackathons.length > 0"
                    >
                        <b-form
                            class="navbar-search form-inline navbar-search-light"
                            id="navbar-search-main"
                            @submit.prevent="on_search_submit"
                        >
                            <b-form-group class="mb-0">
                                <b-input-group
                                    class="input-group-alternative input-group-merge"
                                >
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"
                                            ><i class="fas fa-search"></i
                                        ></span>
                                    </div>
                                    <b-form-input
                                        placeholder="Search"
                                        type="text"
                                        v-model="search.search_term"
                                        @keyup="on_keyup_search"
                                    >
                                    </b-form-input>

                                    <input
                                        type="submit"
                                        ref="search_form_submit"
                                        hidden
                                    />
                                </b-input-group>
                            </b-form-group>
                            <Transition>
                                <i
                                    v-if="search.is_loading"
                                    class="fas fa-spinner fa-spin ml--4 mt-2 text-muted"
                                ></i>
                            </Transition>
                            <button
                                type="button"
                                class="close"
                                data-action="search-close"
                                data-target="#navbar-search-main"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </b-form>
                    </b-col>

                    <b-col
                        class="text-right"
                        v-if="filtered_hackathons.length > 0"
                    >
                        <el-tooltip content="Add" placement="top">
                            <router-link
                                :to="{
                                    path: '/hackathon/new/timeline',
                                }"
                            >
                                <base-button
                                    type="primary"
                                    round
                                    icon
                                    size="sm"
                                >
                                    <span class="btn-inner"
                                        ><i class="fa fa-plus"></i
                                    ></span>
                                </base-button>
                            </router-link>
                        </el-tooltip>
                    </b-col>
                </b-row>
                <b-row v-if="filtered_hackathons.length > 0">
                    <b-col
                        v-for="hackathon in filtered_hackathons"
                        :key="hackathon.id"
                        sm="12"
                        md="4"
                    >
                        <ListHackathonCard
                            :id="hackathon.id"
                            :name="hackathon.name"
                            :description="hackathon.description"
                            :phase="hackathon.phaseDisplay"
                            :is_creator="hackathon.isCreator"
                            :is_manager="hackathon.isManager"
                            :starts="hackathon.startDatetime"
                            :organization="hackathon.organization__name"
                        />
                    </b-col>
                </b-row>
                <b-row
                    v-else-if="
                        filtered_hackathons.length < 1 && hackathons.length > 0
                    "
                >
                    <b-col class="text-center">
                        <h3 class="text-muted">
                            No hackathons found matching your search
                        </h3>
                    </b-col>
                </b-row>
                <b-row v-else-if="!$apollo.loading">
                    <b-col class="text-center">
                        <h3>
                            You don't have any hackathons yet, create your first
                            hackathon.
                        </h3>
                        <el-tooltip content="Add" placement="top">
                            <router-link
                                :to="{
                                    path: '/hackathon/new/timeline',
                                }"
                            >
                                <base-button
                                    type="primary"
                                    round
                                    icon
                                    size="sm"
                                >
                                    <span class="btn-inner"
                                        ><i class="fa fa-plus"></i
                                    ></span>
                                </base-button>
                            </router-link>
                        </el-tooltip>
                    </b-col>
                </b-row>
                <b-row v-else>
                    <b-col class="text-center">
                        <Transition>
                            <h1>
                                <i
                                    class="fas fa-spinner fa-spin ml--4 mt-2 text-muted"
                                ></i>
                            </h1>
                        </Transition>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { GET_ALL_HACKATHON_HACKATHON_ORGANIZING } from "@/graphql/queries";
import { Table, TableColumn, Tooltip } from "element-ui";
import { debounce } from "debounce";

import ListHackathonCard from "@/views/Components/Hackathon/Discovery/ListHackathonCard.vue";

export default {
    name: "ListMyHackathons",

    components: {
        ListHackathonCard,
        [Tooltip.name]: Tooltip,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
    },
    apollo: {
        allHackathonHackathon: {
            query: GET_ALL_HACKATHON_HACKATHON_ORGANIZING,
            result(res) {
                this.hackathons = [];
                this.filtered_hackathons = this.hackathons;

                if (
                    "allHackathonHackathon" in res.data &&
                    res.data.allHackathonHackathon !== null
                ) {
                    let f_data = graph_utils.flatten_objects_recursive(
                        graph_utils.apollo_to_obj_recursive(
                            res.data.allHackathonHackathon
                        )
                    );

                    f_data.forEach((el) => {
                        this.hackathons.push(el);
                    });
                    this.filtered_hackathons = this.hackathons;
                }
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    creator: this.$store.getters.getUserIdB64,
                };
            },
        },
    },

    data() {
        return {
            hackathons: [],
            filtered_hackathons: [],
            search: {
                search_term: "",
                is_loading: false,
            },
        };
    },

    methods: {
        on_keyup_search() {
            this.search.is_loading = true;
            this.debounced_on_keyup_search();
        },

        debounced_on_keyup_search: debounce(function () {
            this.$refs.search_form_submit.click();
        }, 1000),
        on_search_submit() {
            if (this.search.search_term === "") {
                this.filtered_hackathons = this.hackathons;
                setTimeout(() => {
                    this.search.is_loading = false;
                }, 500);
            } else {
                this.filtered_hackathons = this.filter_hackathons(
                    this.search.search_term,
                    this.hackathons
                );
                this.search.is_loading = false;
            }
        },

        filter_hackathons(search_term, in_array) {
            let out_array = in_array.filter((item) => {
                if (item.name === undefined) {
                    item.name = "";
                }
                if (item.detail_description === undefined) {
                    item.detail_description = "";
                }
                if (item.organization__name === undefined) {
                    item.organization__name = "";
                }

                return (
                    item.name
                        .toLowerCase()
                        .includes(search_term.toLowerCase()) ||
                    item.description
                        .toLowerCase()
                        .includes(search_term.toLowerCase()) ||
                    item.organization__name
                        .toLowerCase()
                        .includes(search_term.toLowerCase()) ||
                    item.phaseDisplay
                        .toLowerCase()
                        .includes(search_term.toLowerCase())
                );
            });
            return out_array;
        },
    },

    created() {
        global_event_emitter.$on("hackathon_list_needs_updating", () => {
            setTimeout(() => {
                this.$apollo.queries.allHackathonHackathon.refetch();
            }, 500);
        });
    },
    mounted() {
        this.$apollo.queries.allHackathonHackathon.refetch();
    },
    beforeDestroy() {
        global_event_emitter.$off("hackathon_list_needs_updating");
    },
};
</script>

<style></style>
